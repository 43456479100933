import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
    /* poppins-regular - latin */
    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      src: local(''),
          url('/fonts/poppins-v20-latin/poppins-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('/fonts/poppins-v20-latin/poppins-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
    /* poppins-500 - latin */
    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      src: local(''),
          url('/fonts/poppins-v20-latin/poppins-v20-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('/fonts/poppins-v20-latin/poppins-v20-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
    /* poppins-600 - latin */
    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      src: local(''),
          url('/fonts/poppins-v20-latin/poppins-v20-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('/fonts/poppins-v20-latin/poppins-v20-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
    /* poppins-700 - latin */
    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      src: local(''),
          url('/fonts/poppins-v20-latin/poppins-v20-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('/fonts/poppins-v20-latin/poppins-v20-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

  * {
    font-family: 'Poppins', sans-serif;
  }
  body {
    // background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  

  meter::-webkit-meter-bar {
    background: none; /* Required to get rid of the default background property */
    background-color: #333333;
    /* box-shadow: 0 5px 5px -5px #333 inset; */
  }
  .unlock::-webkit-meter-bar {
    background-color: #9c9c9c;
  }

  meter::-webkit-meter-optimum-value {
    background: none; /* Required to get rid of the default background property */
    background-color: #5ac132;
  }
  meter::-webkit-meter-suboptimum-value,
  meter::-webkit-meter-even-less-good-value {
    background: none; /* Required to get rid of the default background property */
    background-color: #e92222;
  }

  .ant-modal {
    border-radius: 16px;
    overflow: hidden;

    .ant-modal-content {
      background-color: transparent;
      background-image: url("/images/modal/background.png");
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);

      .ant-modal-header {
        background: linear-gradient(111.68deg,#F2ECF2 0%,#E8F2F6 100%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        padding: 12px 24px;
        border-radius: 16px;
        border: none;

        ${({ theme }) => theme.mediaQueries.md} {
          padding: 20px 80px;
        }

        .ant-modal-title {
          text-align: center;
          font-size: 20px;
          line-height: 1.25;
          background: linear-gradient(90deg, #6b25b5 0%, #c54a89 105.52%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: bold;

          ${({ theme }) => theme.mediaQueries.md} {
            font-size: 28px;
          }
        }
      }

      .ant-modal-close {
        top: 4px;

        ${({ theme }) => theme.mediaQueries.md} {
          top: 15px;
        }
      }
    }
  }
`

export default GlobalStyle
