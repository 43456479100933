import { ContextApi } from '@pancakeswap/localization'
import {
  MenuItemsType,
  SwapFillIcon,
  SwapIcon
} from '@pancakeswap/uikit'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Homepage'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/',
    showItemsOnMobile: false,
    items: [],
    showOnMobile: false,
  },
  {
    label: t('Swap'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/swap',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('Liquidity'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/liquidity',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('Launchpad'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/launchpad',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('Predict'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/predict',
    showItemsOnMobile: false,
    items: [],
  },
] 

export default config
