import { ChainId, MULTICALL_ADDRESS_MAP } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { VaultKey } from 'state/types'

export const getAddress = (address: Address, chainId?: number): string => {
  return address[chainId] ? address[chainId] : address[ChainId.BSC]
}
export const getMulticallAddress = (chainId?: number) => {
  return getAddress(MULTICALL_ADDRESS_MAP, chainId)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getTradingCompetitionAddressMoD = () => {
  return getAddress(addresses.tradingCompetitionMoD)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getVaultPoolAddress = (vaultKey: VaultKey) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey])
}

export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}

export const getCakeFlexibleSideVaultAddress = () => {
  return getAddress(addresses.cakeFlexibleSideVault)
}
export const getBunnySpecialXmasAddress = () => {
  return getAddress(addresses.bunnySpecialXmas)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getZapAddress = () => {
  return getAddress(addresses.zap)
}
export const getICakeAddress = () => {
  return getAddress(addresses.iCake)
}

export const getTeamPullAddress = (chainId) => {
  return getAddress(addresses.teamPull, chainId)
}

export const getNftFarmAddress = () => {
  return getAddress(addresses.nftFarm, ChainId.DX_CHAIN)
}

export const getPredictAddress = () => {
  return getAddress(addresses.predict, ChainId.DX_CHAIN)
}

export const getPriceAddress = () => {
  return getAddress(addresses.price, ChainId.DX_CHAIN)
}
